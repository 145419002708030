<template>
  <div class="lg:mt-5 lg:px-4 max-w-2xl mx-auto side-nav short">
    <vue-headful
      title="Settings - Language | Octoa"
    />

    <div class="flex flex-wrap mb-5">
      <div class="w-full lg:w-1/4">
        <setting-menu />
      </div>

      <div class="w-full lg:w-3/4">
        <div
          v-if="loading"
          class="text-center"
        >
          <img
            src="@/assets/img/icons/loader.svg"
            width="50"
            class="mt-32"
          >
        </div>
        <div
          v-else
          class="pb-20"
        >
          <div class="bg-white h-full mx-4 my-4 px-4 py-4 lg:mx-0 lg:my-0 lg:px-8 lg:py-8 border-grey-lighter border rounded">
            <h2 class="font-bold mb-5">
              API Integration
            </h2>
            <p class="leading-loosen md:mr-20">
              Below you will find the secret key linked to your account, this key allow external web services to authenticate request for you. For example, Flothemes offers WordPress themes for your website and now you can also receive client inquiry directly into your account.
            </p>
            <div class="mt-5">
              <div class="font-bold">
                Secret key
              </div>
              <div class="flex w-full mt-2">
                <div class="w-full lg:w-1/2">
                  <input
                    v-model="secretKey"
                    type="text"
                    disabled="disabled"
                  >
                </div>
                <div class="ml-4">
                  <button
                    class="green-btn"
                    @click="copy"
                  >
                    Copy
                  </button>
                </div>
              </div>
            </div>
            <div class="mt-5 bg-blue-washed-light rounded py-4 px-4 leading-loosen">
              This secret key is used to authenticate requests and create data for you. Keep it to yourself at all times!
            </div>

            <hr class="mt-6 mb-5">
            <h2 class="font-bold mb-5">
              <img
                src="@/assets/img/logos/moneybird.png"
                width="200"
              >
              <img
                src="@/assets/img/icons/ico-beta.svg"
                class="align-top ml-2 "
              >
            </h2>
            <p class="leading-normal">
              Connect your Moneybird account for bookkeeping purpose. You will have the ability choose and export invoices directly to Moneybird under Invoices overview.
            </p>
            <div v-if="!isMoneybirdLoading">
              <button
                v-if="!isMoneybirdConnected"
                class="mt-5 button btn__content-text"
                @click="connectMoneybird"
              >
                Connect with Moneybird
              </button>

              <button
                v-else
                class="mt-5 button btn__content-text"
                @click="disconnectMoneybird"
              >
                Disconnect with Moneybird
              </button>

              <div
                v-show="isMoneybirdConnected"
                class="my-5"
              >
                <div>
                  <v-checkbox
                    id="autoExportInvoices"
                    v-model="autoExportInvoices"
                    label="Auto export invoices on sent"
                    :checked="autoExportInvoices"
                    @input="toggleAutoExportInvoices"
                  />
                  <v-help-tooltip
                    :options="autoExportInvoicesContent"
                  />
                </div>
                <div class="mt-5">
                  <v-checkbox
                    id="alwaysReplaceData"
                    v-model="alwaysReplaceData"
                    :checked="alwaysReplaceData"
                    label="Always replace data into Moneybird"
                    @input="toggleAlwaysReplaceData"
                  />
                  <v-help-tooltip
                    :options="alwaysReplaceDataContent"
                  />
                </div>
              </div>
            </div>
            <div
              v-else
              class="mt-5"
            >
              <img
                src="@/assets/img/icons/loader.svg"
                width="35"
              >
            </div>

            <hr class="mt-6 mb-5">
            <div class="mb-5 flex">
              <div class="flex-none">
                <img
                  src="@/assets/img/icons/google-calendar-icon.svg"
                  width="35"
                >
              </div>
              <div
                class="flex-1 ml-3 m-auto text-xl"
                style="font-family: Google Sans,Roboto,Arial,sans-serif; color: #3c4043;"
              >
                Google Calendar
                <img
                  src="@/assets/img/icons/ico-beta.svg"
                  class="align-top ml-2 "
                >
              </div>
            </div>
            <p class="leading-normal">
              Connect your Google account to sync your Octoa projects to Google Calendar.
            </p>
            <div v-if="!isGoogleCalendarLoading">
              <button
                v-if="!isGoogleCalendarConnected"
                class="mt-5 button btn__content-text"
                @click="connectGoogleCalendar"
              >
                Connect your Google Calendar
              </button>

              <button
                v-else
                class="mt-5 button btn__content-text"
                @click="disconnectGoogleCalendar"
              >
                Disconnect your Google Calendar
              </button>

              <div
                v-if="isGoogleCalendarConnected"
                class="my-5"
              >
                <div>
                  <div class="font-bold">
                    Settings
                  </div>
                  <div class="w-full">
                    Select the calendar to sync to:&nbsp;
                    <div class="select-container">
                      <select v-model="calendarToSyncTo">
                        <option
                          v-for="calendar in calendars"
                          :key="calendar.id"
                          :value="calendar.id"
                        >
                          {{ calendar.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="w-full mt-3">
                    Select the project phase of the projects you want to sync to your calendar:&nbsp;
                    <div class="select-container">
                      <select v-model="projectPhaseToSync">
                        <option
                          v-for="(value, key) in projectPhases"
                          :key="key"
                          :value="key"
                        >
                          {{ value }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <button
                    v-if="calendarToSyncTo > 0"
                    class="mt-5 green-btn btn__content-text"
                    @click="saveCalendarSettings"
                  >
                    Save settings and sync projects to calendar
                  </button>
                  <div
                    v-if="isGoogleCalendarSaving"
                    class="mt-5"
                  >
                    <img
                      src="@/assets/img/icons/loader.svg"
                      width="35"
                    >
                  </div>
                </div>
              </div>
              <div
                v-if="isGoogleCalendarLoading"
                class="mt-5"
              >
                <img
                  src="@/assets/img/icons/loader.svg"
                  width="35"
                >
              </div>
            </div>

            <hr class="mt-6 mb-5">
            <div class="mb-5 flex">
              <div class="flex-none">
                <img
                  src="@/assets/img/icons/pictime-logo.svg"
                  width="35"
                >
              </div>
              <div
                class="flex-1 ml-3 m-auto text-xl"
                style="font-family: Google Sans,Roboto,Arial,sans-serif; color: #3c4043;"
              >
                Pic-Time
                <img
                  src="@/assets/img/icons/ico-beta.svg"
                  class="align-top ml-2 "
                >
              </div>
            </div>
            <p class="leading-normal">
              Connect your Pic-Time account to sync your Octoa projects to Pic-Time.
            </p>
            <div v-if="!isPictimeLoading">
              <button
                v-if="!isPictimeConnected"
                class="mt-5 button btn__content-text"
                @click="connectPictime"
              >
                Connect your Pic-Time account
              </button>

              <button
                v-else
                class="mt-5 button btn__content-text"
                @click="disconnectPictime"
              >
                Disconnect your Pic-Time account
              </button>

              <div
                v-if="isGoogleCalendarLoading"
                class="mt-5"
              >
                <img
                  src="@/assets/img/icons/loader.svg"
                  width="35"
                >
              </div>


              <hr class="mt-6 mb-5">
              <h2 class="font-bold mb-5">
                More integrations coming soon
              </h2>

              <a
                href="https://forms.gle/MUizXdGHsThxmj3r8"
                target="_blank"
                class="text-green underline mt-3"
              >Give us feedback</a> on what to support next.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/auth'
import config from '@/config'

export default {
  name: 'SettingsIntegration',
  data() {
    return {
      loading: true,
      user: auth.user(),
      secretKey: '',
      isMoneybirdLoading: true,
      isMoneybirdConnected: false,
      isPictimeLoading: true,
      isPictimeConnected: false,
      isGoogleCalendarLoading: true,
      isGoogleCalendarConnected: false,
      isGoogleCalendarSaving: false,
      projectPhases: {
        'inquiry': 'Inquiry',
        'proposal': 'Proposal',
        'booked': 'Booked',
        'post-production': 'Post Production',
        'finished': 'Finished'
      },
      calendarToSyncTo: null,
      calendars: [],
      projectPhaseToSync: null,
      autoExportInvoices: true,
      autoExportInvoicesContent: {
        content: 'As soon as an invoice is sent, Moneybird will receive a copy of the invoice.',
      },
      alwaysReplaceData: true,
      alwaysReplaceDataContent: {
        content: 'Any existing contacts or invoices data will always be overwritten into Moneybird.'
      },
    }
  },
  async mounted() {
    this.loading = false
    this.secretKey = this.user.company.secret_key
    await this.refresh()

    //Moneybird: fetch access token
    if (this.$route.query.type == 'moneybird' && this.$route.query.code) {
      try {
        const {data} = await this.$api.get('moneybird').fetchAccessToken(this.user.company.id, {authCode: this.$route.query.code})
        await this.refresh()
      } catch (e) {
        // this.$toasted.global.general_error({
        //   message : 'Could not fetch access token from Moneybird. If the problem presist, please contact us.'
        // })
      }
    }

    if (this.$route.query.pictime) {
      this.$toasted.global.general_success({
        message: 'Pic-Time is successfully connected!'
      })
    }

    this.isMoneybirdLoading = false
    this.isPictimeLoading = false
    this.isGoogleCalendarLoading = false
  },
  methods: {
    async toggleAutoExportInvoices() {
      this.$toasted.clear()
      try {
        const {data} = await this.$api.get('moneybird').toggleAutoExportInvoices(this.user.company.id)
        await this.refresh()

        this.$toasted.global.general_success({
          message: 'Moneybird settings updated.'
        })
      } catch (e) {
        this.$toasted.global.general_error({
          message: 'Moneybird settings error, please try again.'
        })
      }
    },
    async toggleAlwaysReplaceData() {
      this.$toasted.clear()
      try {
        const {data} = await this.$api.get('moneybird').toggleAlwaysReplaceData(this.user.company.id)
        await this.refresh()

        this.$toasted.global.general_success({
          message: 'Moneybird settings updated.'
        })
      } catch (e) {
        this.$toasted.global.general_error({
          message: 'Moneybird settings error, please try again.'
        })
      }
    },
    async refresh() {
      try {
        await this.$store.dispatch('company/refreshCompanyDetails')

        const company = this.$store.getters['company/details']

        if (company.moneybirdSettings.auto_export_invoices) {
          this.autoExportInvoices = true
        } else {
          this.autoExportInvoices = false
        }

        if (company.moneybirdSettings.always_replace_data) {
          this.alwaysReplaceData = true
        } else {
          this.alwaysReplaceData = false
        }

        if (company.moneybirdSettings.access_token) {
          this.isMoneybirdConnected = true
        } else {
          this.isMoneybirdConnected = false
        }

        if (company.pictimeSettings.access_token) {
          this.isPictimeConnected = true
        } else {
          this.isPictimeConnected = false
        }

        if (company.googleCalendarSettings.token_id) {
          this.isGoogleCalendarConnected = true
        } else {
          this.isGoogleCalendarConnected = false
        }

        if (company.googleCalendarSettings.project_phase_to_sync) {
          this.projectPhaseToSync = company.googleCalendarSettings.project_phase_to_sync
        } else {
          this.projectPhaseToSync = null
        }

        if (company.googleCalendarSettings.calendars) {
          let calendars = company.googleCalendarSettings.calendars
          let calendarToSyncTo = calendars.find(calendar => calendar.is_selected) ? calendars.find(calendar => calendar.is_selected).id : null
          if (!calendarToSyncTo) {
            let defaultCalendar = {id: 0, is_selected: true, name: 'Select a calendar'}
            calendars.unshift(defaultCalendar)
            this.calendarToSyncTo = defaultCalendar.id
          } else {
            this.calendarToSyncTo = calendarToSyncTo
          }
          this.calendars = calendars
        } else {
          this.calendars = []
        }

        auth.refreshUser()

      } catch (e) {
        this.$toasted.global.api_error(e)
      }
    },
    async connectMoneybird() {
      try {
        const {data} = await this.$api.get('moneybird').connect(this.user.company.id)
        window.location.href = data.authUrl
      } catch (e) {
        this.$toasted.global.general_error({
          message: 'Moneybird authorization error, please try again.'
        })
      }
    },
    async disconnectMoneybird() {
      try {
        const {data} = await this.$api.get('moneybird').disconnect(this.user.company.id)
        await this.refresh()
      } catch (e) {
        this.$toasted.global.general_error({
          message: 'Moneybird authorization error, please try again.'
        })
      }
    },
    async connectPictime() {
      const url = location.protocol + '//' + location.host
      try {
        window.location.href =  'https://octoa.pic-time.com/oauth?responseType=clientToken&appId=AAAAAMAAAAA3dtYQe1uS7JEllECE&redirectUrl=' + url + '/settings/pictime-callback'
      } catch (e) {
        this.$toasted.global.general_error({
          message: 'Pic-Time authorization error, please try again.'
        })
      }
    },
    async disconnectPictime() {
      try {
        const {data} = await this.$api.get('pictime').disconnect(this.user.company.id)
        await this.refresh()

        this.$toasted.global.general_success({
          message: 'Pic-Time disconnected.'
        })
      } catch (e) {
        this.$toasted.global.general_error({
          message: 'Pic-Time authorization error, please try again.'
        })
      }
    },
    async connectGoogleCalendar() {
      try {
        const {data} = await this.$api.get('googleCalendar').connect(this.user.company.id)
        window.location.href = data.authUrl
      } catch (e) {
        this.$toasted.global.general_error({
          message: 'Google Calendar authorization error, please try again.'
        })
      }
    },
    async disconnectGoogleCalendar() {
      try {
        const {data} = await this.$api.get('googleCalendar').disconnect(this.user.company.id)
        await this.refresh()

        this.$toasted.global.general_success({
          message: 'Google Calendar disconnected.'
        })

      } catch (e) {
        this.$toasted.global.general_error({
          message: 'Google Calendar authorization error, please try again.'
        })
      }
    },
    async saveCalendarSettings() {
      this.$toasted.clear()
      this.isGoogleCalendarSaving = true
      try {
        const {data} = await this.$api.get('googleCalendar').saveSettingsAndSyncToCalendar(this.user.company.id, {project_phase_to_sync: this.projectPhaseToSync, calendar_to_sync_to: this.calendarToSyncTo})
        await this.refresh()

        this.$toasted.global.general_success({
          message: 'Google Calendar settings updated and sync of projects to the selected calendar completed.'
        })
      } catch (e) {
        this.$toasted.global.general_error({
          message: 'Google Calendar settings error, please try again.'
        })
        console.error(e)
      }
      this.isGoogleCalendarSaving = false
    },
    copy() {
      let dummy = document.createElement('textarea')
      document.body.appendChild(dummy)
      dummy.value = this.secretKey
      dummy.select()
      document.execCommand('copy')
      document.body.removeChild(dummy)

      this.$toasted.global.general_success({
        message: 'Secret key copied to your clipboard.'
      })
    },
  },
}
</script>
